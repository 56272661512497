define("discourse/plugins/discourse-custom-wizard/discourse/models/custom-wizard-field", ["exports", "@ember/object", "discourse/plugins/discourse-custom-wizard/discourse/mixins/valid-state", "discourse-common/utils/decorators", "discourse/plugins/discourse-custom-wizard/discourse/lib/wizard"], function (_exports, _object, _validState, _decorators, _wizard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const StandardFieldValidation = ["text", "number", "textarea", "dropdown", "tag", "image", "user_selector", "text_only", "composer", "category", "group", "date", "time", "date_time"];
  var _default = _exports.default = _object.default.extend(_validState.default, (_dec = (0, _decorators.default)("wizardId", "stepId", "id"), _dec2 = (0, _decorators.default)("i18nKey", "label"), _dec3 = (0, _decorators.default)("i18nKey", "placeholder"), _dec4 = (0, _decorators.default)("i18nKey", "description"), (_obj = {
    id: null,
    type: null,
    value: null,
    required: null,
    warning: null,
    i18nKey(wizardId, stepId, id) {
      return `${wizardId}.${stepId}.${id}`;
    },
    translatedLabel(i18nKey, label) {
      return (0, _wizard.translationOrText)(`${i18nKey}.label`, label);
    },
    translatedPlaceholder(i18nKey, placeholder) {
      return (0, _wizard.translationOrText)(`${i18nKey}.placeholder`, placeholder);
    },
    translatedDescription(i18nKey, description) {
      return (0, _wizard.translationOrText)(`${i18nKey}.description`, description);
    },
    check() {
      if (this.customCheck) {
        return this.customCheck();
      }
      let valid = this.valid;
      if (!this.required) {
        this.setValid(true);
        return true;
      }
      const val = this.get("value");
      const type = this.get("type");
      if (type === "checkbox") {
        valid = val;
      } else if (type === "upload") {
        valid = val && val.id > 0;
      } else if (StandardFieldValidation.indexOf(type) > -1) {
        valid = val && val.toString().length > 0;
      } else if (type === "url") {
        valid = true;
      }
      this.setValid(Boolean(valid));
      return valid;
    }
  }, (_applyDecoratedDescriptor(_obj, "i18nKey", [_dec], Object.getOwnPropertyDescriptor(_obj, "i18nKey"), _obj), _applyDecoratedDescriptor(_obj, "translatedLabel", [_dec2], Object.getOwnPropertyDescriptor(_obj, "translatedLabel"), _obj), _applyDecoratedDescriptor(_obj, "translatedPlaceholder", [_dec3], Object.getOwnPropertyDescriptor(_obj, "translatedPlaceholder"), _obj), _applyDecoratedDescriptor(_obj, "translatedDescription", [_dec4], Object.getOwnPropertyDescriptor(_obj, "translatedDescription"), _obj)), _obj)));
});